<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ this.gideonCheckoutPageInfo.Label_Page_Title }}</h1>
    </header>
    <b-container class="top">
      <b-row class="top-row" v-if="!displayOrderPreview">
        <b-col sm="12" xs="12" class="top-col mb-5">
          <top-nav-search class="top-nav-search" :showDescriptionText="showDescriptionText"
            :showMerchTotal="showMerchTotal" :backRouteName="`store-cart`"
            :backLabel="gideonCheckoutPageInfo.Label_Back_Description" :i18n="translations.components">
          </top-nav-search>
        </b-col>
      </b-row>
      <b-row class="tile-row" v-if="!displayOrderPreview">
        <b-col sm="12" xs="12" class="tile-col-title mb-5">
          <div class="title">
            <div>
              {{
                  gideonStoreCartCheckoutType === 'merchandise'
                    ? gideonCheckoutPageInfo.Label_CardMerchandiseTitle
                    : gideonCheckoutPageInfo.Label_CardScriptureTitle
              }}
            </div>
          </div>
          <div class="category-ddl">
            <b-button variant="secondary" size="sm" class="altButton mr-2"
              @click="clearCart(gideonStoreCartCheckoutType)">{{ this.gideonCheckoutPageInfo.Button_ClearCart }}
            </b-button>
            <b-button variant="secondary" size="sm" class="altButton mr-2" @click="continueShopping()">{{
                this.gideonCheckoutPageInfo.Button_ContinueShopping
            }}</b-button>
            <b-button variant="secondary" size="sm" class="altButton mr-2" @click="reviseOrder()">{{
                this.gideonCheckoutPageInfo.Button_ReviseOrder
            }}</b-button>
          </div>
        </b-col>
        <b-col sm="12" xs="12" class="tile-col-details">
          <div class="label-strong">{{ this.gideonCheckoutPageInfo.Label_CardSubTitle }}</div>
          <div class="details-div" v-if="translations.components">
            <ServiceCard v-for="(item, index) in serviceCardItems" :i18n="translations.components" :key="index"
              :obj="item" :ignore="ignore" @edit_requested="reviseOrder()"></ServiceCard>
          </div>
        </b-col>
        <b-col sm="6" xs="12" class="tile-col-details mb-3">
          <div class="label-strong">{{ this.gideonCheckoutPageInfo.Label_Shipping }}</div>
          <div class="form-div">
            <div class="mb-3">
              <b-form-select v-model="selectedShippingMember" :options="shippingMembers"
                class="form-control col-left-input g-select flex-0 mr-3" style="width: 75%"
                @change="selectAShippingMember($event)">
                <template slot="first">
                  <option :value="null" disabled>{{ this.gideonCheckoutPageInfo.Default_ShippingMember }}</option>
                </template>
              </b-form-select>
              <b-button :name="`contactFindButton`" variant="primary" class="flex-1 btn-pl-xsm" style="float: right"
                @click="showModal($event, 'personSearchModal')">
                {{ gideonCheckoutPageInfo.Button_ShippingFind }}
              </b-button>
            </div>
            <div class="mb-3">
              <b-form-radio-group v-model="shippingFilter" v-slot="{ ariaDescribedby }">
                <b-form-radio :aria-describedby="ariaDescribedby" name="some-radios" value="residential">
                  {{ gideonCheckoutPageInfo.Label_ShippingResidential }}
                </b-form-radio>
                <b-form-radio :aria-describedby="ariaDescribedby" name="some-radios" value="commercial">
                  {{ gideonCheckoutPageInfo.Label_ShippingCommercial }}</b-form-radio>
              </b-form-radio-group>
            </div>
            <div class="mb-3">
              <b-form-select v-model="selectedShippingAddress" :options="shippingAddresses"
                class="form-control col-left-input g-select flex-0 mr-3" @change="selectAShippingAddress($event)">
                <template slot="first">
                  <option :value="null" disabled>{{ this.gideonCheckoutPageInfo.Default_ShippingAddress }}</option>
                </template>
              </b-form-select>
              <span v-html="selectedShipAddressDisplay.address"></span>
            </div>
            <div class="mb-3">
              <b-button :name="`addShipping`" variant="primary" class="flex-1 btn-pl-xsm"
                @click="addNewShippingAddress()">
                {{ this.gideonCheckoutPageInfo.Button_ShippingAddNewAddress }}
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col sm="6" xs="12" class="tile-col-details mb-3">
          <div class="label-strong">{{ this.gideonCheckoutPageInfo.Label_AdditionalInfo }}</div>
          <div class="form-div">
            <div class="special-instructions">
              {{ this.gideonCheckoutPageInfo.Label_SpecialInstructions }}
            </div>
            <b-form-textarea v-if="this.selectedShippingAddress" id="message-textarea" v-model="AdditionalInfoAdr3"
              rows="1" max-rows="2" maxlength="30"></b-form-textarea>
            <div v-if="this.gideonStoreCartCheckoutType === 'merchandise'">
              <b-form-checkbox id="checkbox-1" v-model="expeditedProcessing" name="checkbox-expedite" value="true"
                unchecked-value="false" class="mt-3">
                {{ this.gideonCheckoutPageInfo.Label_ExpeditedProcessing }}
              </b-form-checkbox>
              <div>
                <strong>{{ translations.tcExpeditedProcessingMessage }}</strong>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="6" xs="12" class="tile-col-details mb-3"
          v-if="this.gideonStoreCartCheckoutType === 'merchandise' && this.CartTotal > 0">
          <div class="label-strong">{{ this.gideonCheckoutPageInfo.Label_Billing }}</div>
          <div class="form-div">
            <div class="mb-3">
              <b-form-select v-model="selectedBillingAddress" :options="billingAddresses"
                class="form-control col-left-input g-select flex-0 mr-3">
                <template slot="first">
                  <option :value="null" disabled>{{ this.gideonCheckoutPageInfo.Default_BillingAddress }}</option>
                </template>
              </b-form-select>
              {{ selectedBillAddressDisplay.address }}
            </div>
            <div class="mb-3">
              <b-button :name="`addShipping`" variant="primary" class="flex-1 btn-pl-xsm"
                @click="addNewBillingingAddress()">
                {{ this.gideonCheckoutPageInfo.Button_BillingAddNewAddress }}
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col sm="6" xs="12" class="tile-col-details mb-3"
          v-if="this.gideonStoreCartCheckoutType === 'merchandise' && this.CartTotal > 0">
          <div class="label-strong">{{ this.gideonCheckoutPageInfo.Label_CreditCardInfo }}</div>
          <div class="form-div">
            <b-form-input required v-model="creditCardNumber" id="txt-creditcard"
              :placeholder="gideonCheckoutPageInfo.Placeholder_CreditCardNumber" class="inputCCNumber mb-3"
              maxlength="16"></b-form-input>
            <b-form-input required v-model="creditCardName" id="txt-creditcardname"
              :placeholder="gideonCheckoutPageInfo.Placeholder_NameOnCard" class="inputCCName mb-3"></b-form-input>
            <div class="special-instructions mb-3">
              {{ this.gideonCheckoutPageInfo.Label_ExpirationDate }}
              <b-form-select v-model="creditCardExpMonth" :options="optionsExpireMonth"
                class="form-control col-left-input g-select flex-0 ml-3 mr-3 selectMonth">
                <template slot="first">
                  <option :value="null" disabled>{{ this.gideonCheckoutPageInfo.Placeholder_ExpirationMonth }}</option>
                </template>
              </b-form-select>
              <b-form-select v-model="creditCardExpYear" :options="optionsExpireYear"
                class="form-control col-left-input g-select flex-0 mr-3 selectYear">
                <template slot="first">
                  <option :value="null" disabled>{{ this.gideonCheckoutPageInfo.Placeholder_ExpirationYear }}</option>
                </template>
              </b-form-select>
            </div>
            <div class="special-instructions">
              {{ this.gideonCheckoutPageInfo.Label_CvvNumber }}
              <b-form-input required v-model="creditCardCVC" id="txt-creditcardcvc" class="inputCVC ml-3 mr-3"
                placeholder="000" maxlength="4"></b-form-input>
              <b-link id="tooltip-button-1" class="what-link" @click="showToolTip1 = !showToolTip1">{{
                  this.gideonCheckoutPageInfo.Label_WhatsThis
              }}</b-link>

              <b-tooltip :show.sync="showToolTip1" custom-class="tooltip-class" target="tooltip-button-1"
                placement="top">
                <div v-html="this.gideonCheckoutPageInfo.Label_WhatsThisInformation"></div>
              </b-tooltip>
            </div>
          </div>
        </b-col>
        <b-col sm="12" xs="12" class="tile-col-details col-cart-total mb-3">
          <div class="label-strong mb-4">
            {{ this.gideonCheckoutPageInfo.Label_OrderTotal }}: <span class="cartTotal mb-4">${{ this.CartTotal
            }}</span><br />
            <div class="mt-4" v-if="this.gideonStoreCartCheckoutType === 'scripture'">
              {{ this.gideonCheckoutPageInfo.Label_CampFundBalance }}:
              <span class="cartTotal">{{
                  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                    CampFundBalanceAfterPurchase
                  )
              }}</span><br />
              {{ this.gideonCheckoutPageInfo.Label_ServiceTestamentTotal }}:
              <span class="cartTotal">{{
                  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                    gideonCheckoutPageInfo.ServiceTestamentTotal
                  )
              }}</span><br />
            </div>
          </div>
          <div class="details-div">
            <b-button variant="primary" size="sm" class="btn btn-primary btn-top mr-2"
              @click="toggleOrderPreview(true)">{{ gideonCheckoutPageInfo.Button_Preview }}</b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="tile-row" v-if="displayOrderPreview">
        <b-col sm="12" xs="12" class="tile-col-title">
          <div class="title">
            <div>{{ gideonCheckoutPageInfo.Label_Order_Preview }}</div>
          </div>
          <div class="category-ddl">
            <b-button variant="primary" size="sm" class="btn btn-primary btn-top mr-2" @click="handleCheckOut()">{{
                this.gideonCheckoutPageInfo.Button_CompleteOrder
            }}</b-button>
            <b-button variant="secondary" size="sm" class="altButton mr-2" @click="toggleOrderPreview(false)">{{
                gideonCheckoutPageInfo.Label_Edit
            }}</b-button>
          </div>
        </b-col>
        <b-col sm="12" xs="12" class="tile-col-details mb-5">
          <div class="label-strong">
            <i>{{ gideonCheckoutPageInfo.Label_Order_Preview_Details }}</i>
          </div>
          <br />
          <div class="label-strong">{{ gideonCheckoutPageInfo.Label_Items }}</div>
          <b-row class="tile-row">
            <b-col sm="3" xs="12" class="tile-col-details mb-5" v-for="(item, index) in serviceCardItems" :key="index">
              {{ item.vprd_product_name }}<br />
              {{ item.label_item }} {{ item.vprd_prd_code }}<br />
              {{ item.label_quanity }} {{ item.shc_quantity }}<br />
              {{ item.label_price }} ${{ item.sum_line_amount.toFixed(2) }}<br />
            </b-col>
            <b-col sm="3" xs="12" class="tile-col-details mb-5" v-if="expeditedProcessing">
              {{ gideonCheckoutPageInfo.Label_RushCharge }}<br />
              {{ serviceCardItems[0].label_item }} XRUSH<br />
              {{ serviceCardItems[0].label_quanity }} 1<br />
              {{ serviceCardItems[0].label_price }} $15.00<br />
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="3" xs="12" class="tile-col-details mb-5">
          <div class="label-strong">{{ gideonCheckoutPageInfo.Label_Shipping }}</div>
          {{ shippingMemberDisplay }}<br />
          {{ selectedShipAddressDisplay.addr_line1 }}<br />
          <span v-html="selectedShipAddressDisplay.address"></span><br />
        </b-col>
        <b-col sm="3" xs="12" class="tile-col-details mb-5"
          v-if="gideonStoreCartCheckoutType === 'merchandise' && selectedBillingAddress">
          <div class="label-strong">{{ gideonCheckoutPageInfo.Label_Billing }}</div>
          {{ userLogin.name }}<br />
          {{ selectedBillAddressDisplay.addr_line1 }}<br />
          {{ selectedBillAddressDisplay.address }}<br />
        </b-col>
        <b-col sm="3" xs="12" class="tile-col-details mb-5"
          v-if="gideonStoreCartCheckoutType === 'merchandise' && selectedBillingAddress">
          <div class="label-strong">{{ gideonCheckoutPageInfo.Label_CreditCardInfo }}</div>
          {{ creditCardNumber.substring(0, 4) + '************' }}<br />
          {{ creditCardName }}<br />
          {{ creditCardExpMonth }}/{{ creditCardExpYear }}<br />
          ***<br />
        </b-col>
        <b-col sm="3" xs="12" class="tile-col-details mb-5">
          <div class="label-strong">{{ gideonCheckoutPageInfo.Label_AdditionalInfo }}</div>
          {{ AdditionalInfoAdr3 }}<br />
        </b-col>
        <b-col sm="12" xs="12" class="tile-col-details col-cart-total mb-3">
          <div class="label-strong mb-4">
            {{ gideonCheckoutPageInfo.Label_OrderTotal }}
            <span class="cartTotal">${{ CartTotal }}</span>
          </div>
          <div class="label-strong mb-4" v-if="this.gideonStoreCartCheckoutType === 'scripture'">
            {{ this.gideonCheckoutPageInfo.Label_CampFundBalance }}:
            <span class="cartTotal">{{
                new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                  CampFundBalanceAfterPurchase
                )
            }}</span><br />
            {{ this.gideonCheckoutPageInfo.Label_ServiceTestamentTotal }}:
            <span class="cartTotal">{{
                new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                  gideonCheckoutPageInfo.ServiceTestamentTotal
                )
            }}</span><br />
          </div>
          <div class="details-div">
            <b-button variant="primary" size="sm" class="btn btn-primary btn-top mr-2" @click="handleCheckOut()">{{
                this.gideonCheckoutPageInfo.Button_CompleteOrder
            }}</b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <PersonSearch @selectionMade="applyFoundPerson($event)"></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import TopNavSearch from './TopNavSearch.vue'
import ServiceCard from '@/components/service-card/ServiceCard.vue'
import date_data from '@/json/date.json'
import PersonSearch from '@/components/PersonSearch.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'store-cart-checkout',
  mixins: [translationMixin],
  data() {
    return {
      commercialShippingAddressType: '617b5218-7f7e-4f8b-9f2c-0bc41386ac57',
      storeLPPayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_Checkout',
        org_key: '',
        product_type: '',
      },
      storeNavPagePayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_Navigation',
      },
      showDescriptionText: false,
      showMerchTotal: false,
      ignore: {
        edit: true,
        delete: false,
      },
      displayOrderPreview: false,
      selectedShippingMember: null,
      shippingFilter: 'residential',
      selectedShippingAddress: null,
      selectedBillingAddress: null,
      expeditedProcessing: false,
      creditCardNumber: '',
      creditCardName: '',
      creditCardExpMonth: null,
      creditCardExpYear: null,
      creditCardCVC: '',
      showToolTip1: false,
      AdditionalInfoAdr3: '',
      translations: {},
      CartCheckoutMessage: {
        tcErrorProcessingScriptureFunds: 'tcErrorProcessingScriptureFunds',
        tcErrorProcessingOrderPleaseTryAgain: 'tcErrorProcessingOrderPleaseTryAgain',
        tcCreditCardIssue: 'tcCreditCardIssue',
        tcOrderEntryProductError: 'tcOrderEntryProductError',
        tcExpeditedProcessingMessage: 'tcExpeditedProcessingMessage',
      },
    }
  },
  components: {
    topNavSearch: TopNavSearch,
    ServiceCard: ServiceCard,
    PersonSearch: PersonSearch,
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      loadIndividualProfile: 'membership/loadIndividualProfile',
      loadStoreCheckout: 'gideonStore/loadStoreCheckout',
      loadStoreNavigationPage: 'gideonStore/loadStoreNavigationPage',
      loadStoreCheckoutShippingAddress: 'gideonStore/loadStoreCheckoutShippingAddress',
      clearStoreCart: 'gideonStore/clearStoreCart',
      setStoreLastCreditCard: 'gideonStore/setStoreLastCreditCard',
      setStoreOrderId: 'gideonStore/setStoreOrderId',
      addStorePurchase: 'gideonStore/addStorePurchase',
      setSelectedAddress: 'user/setSelectedAddress',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setStoreShippingIndKey: 'gideonStore/setStoreShippingIndKey',
    }),
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        this.storeNavPagePayload.lang = this.prefCulture
        this.storeLPPayload.lang = this.prefCulture
        this.storeLPPayload.product_type = this.gideonStoreCartCheckoutType
        this.storeLPPayload.org_key = this.userCamp.key
        await Promise.all([
          await this.loadStoreCheckout(this.storeLPPayload),
          await this.loadStoreNavigationPage(this.storeNavPagePayload),
        ]).then(() => {
          this.selectedShippingMember =
            this.gideonStoreShippingIndKey.length > 0 ? this.gideonStoreShippingIndKey : this.userLogin.indkey
          this.selectAShippingMember(this.selectedShippingMember)
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async clearCart(product_type) {
      let response = false
      await Promise.all([
        this.setLoadingStatus(true),
        //add in the call to add to cart
        (response = await this.clearStoreCart(product_type)),
      ]).then(() => {
        this.setLoadingStatus(false)
      })
      if (response === false) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorClearingCart,
          confirmButtonText: this.translations.tcOk,
        }).then((result) => {
          return
        })
      }
      this.$router.push({ name: 'store-cart' })
    },
    async applyFoundPerson(evt) {
      this.setLoadingStatus(true),
        this.gideonCheckoutPageInfo.CampMembers.push({ ind_key: evt.ind_key, name: evt.name_text })
      this.selectedShippingMember = evt.ind_key
      this.selectedShippingAddress = null
      await Promise.all([await this.loadStoreCheckoutShippingAddress(evt.ind_key)]).then(() => {
        this.setLoadingStatus(false)
      })
    },
    continueShopping() {
      this.$router.push({ name: 'store-home' })
    },
    reviseOrder() {
      this.$router.push({ name: 'store-cart' })
    },
    async selectAShippingMember(data) {
      this.setLoadingStatus(true), (this.selectedShippingAddress = null)
      await Promise.all([await this.loadStoreCheckoutShippingAddress(data)]).then(() => {
        this.setLoadingStatus(false)
      })
    },
    selectAShippingAddress(data) { },
    validateForm() {
      let rtn = true
      if (!this.selectedShippingMember || !this.selectedShippingAddress) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorShippingMemberAndAddressRequired,
          confirmButtonText: this.translations.tcOk,
        })
      }
      if (this.gideonStoreCartCheckoutType === 'merchandise' && !this.selectedBillingAddress && this.CartTotal > 0) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorBillingAddressRequired,
          confirmButtonText: this.translations.tcOk,
        })
      }
      if (
        this.gideonStoreCartCheckoutType === 'merchandise' &&
        this.CartTotal > 0 &&
        (!this.creditCardNumber === '' ||
          this.creditCardName === '' ||
          !this.creditCardExpMonth ||
          !this.creditCardExpYear ||
          this.creditCardCVC === '')
      ) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorCreditCardRequired,
          confirmButtonText: this.translations.tcOk,
        })
      }
      return rtn
    },
    setCheckoutMessage(msg) {
      // the message returned by the api is a sitecore placeholder text
      // use it to get the translation
      let translatedMessage = ''
      if (msg === this.CartCheckoutMessage.tcErrorProcessingScriptureFunds) {
        translatedMessage = this.translations.tcErrorProcessingScriptureFunds
      } else if (msg === this.CartCheckoutMessage.tcErrorProcessingOrderPleaseTryAgain) {
        translatedMessage = this.translations.tcErrorProcessingOrderPleaseTryAgain
      } else if (msg.includes(this.CartCheckoutMessage.tcOrderEntryProductError)) {
        // for this one, the message includes more than just the sitecore placeholder
        translatedMessage =
          this.translations.tcOrderEntryProductError +
          msg.replace(this.CartCheckoutMessage.tcOrderEntryProductError, '')
      } else if (msg.includes(this.CartCheckoutMessage.tcCreditCardIssue)) {
        // for this one, the message includes more than just the sitecore placeholder
        translatedMessage =
          this.translations.tcCreditCardIssue + msg.replace(this.CartCheckoutMessage.tcCreditCardIssue, '')
      }
      return translatedMessage
    },
    async handleCheckOut() {
      let response = null
      if (this.validateForm()) {
        let lastCC = {
          name: '',
          number: '',
          expiration: '',
        }
        if (this.gideonStoreCartCheckoutType === 'merchandise' && this.CartTotal > 0) {
          lastCC.name = this.creditCardName
          lastCC.number = this.creditCardNumber.substring(0, 1) + 'xxxxxxxxxxxxxxx'
          lastCC.expiration = this.creditCardExpMonth + '/' + this.creditCardExpYear
        }

        let payload = {
          ind_key: this.userLogin.indkey,
          cmp_key: this.userCamp.key,
          product_type: this.gideonStoreCartCheckoutType,
          billing_axe_key: this.selectedBillingAddress,
          shipping_axe_key: this.selectedShippingAddress,
          CreditCardHolderName: this.creditCardName,
          CreditCardNumber: this.creditCardNumber,
          CreditCardSecurityCode: this.creditCardCVC,
          CreditCardYear: this.creditCardExpYear,
          CreditCardMonth: this.creditCardExpMonth,
          ExpeditedShipping: this.expeditedProcessing,
          OrderTotal: this.CartTotal,
          AdditionalInfo: this.AdditionalInfoAdr3,
        }

        // submit data
        await Promise.all([this.setLoadingStatus(true), (response = await this.addStorePurchase(payload))]).then(() => {
          this.setLoadingStatus(false)
          if (response.data.OrderId) {
            this.setStoreOrderId(response.data.OrderId)
            this.setStoreLastCreditCard(lastCC)
            this.$router.push({ name: 'store-confirmation' })
          } else {
            let translatedMessages = ''
            // get the list of errors from the api and translate them
            response.data.ErrorMessages.map((msg) => {
              translatedMessages += this.setCheckoutMessage(msg) + '<br/>'
            })
            this.$swal({
              icon: 'error',
              html: translatedMessages,
              confirmButtonText: this.translations.tcOk,
            }).then((response) => {
              return false
            })
          }
        })
      }
    },
    async addNewShippingAddress() {
      let newAddress = {
        cnt_key: this.userCountryObject.key,
      }
      this.setStoreShippingIndKey(this.selectedShippingMember)
      this.addressStoreAndRedirect(newAddress, 'shipping')
    },
    async addNewBillingingAddress() {
      let newAddress = {
        cnt_key: this.userCountryObject.key,
      }
      this.addressStoreAndRedirect(newAddress, 'billing')
    },
    async addressStoreAndRedirect(data, type) {
      this.setSelectedIndividualKey(type === 'shipping' ? this.selectedShippingMember : this.userLogin.indkey)
      await this.loadIndividualProfile(), this.setSelectedAddress(data)
      this.$router.push({ name: 'add-member-checkout-address' })
    },
    toggleOrderPreview(trueOrFalse) {
      if (this.validateForm()) {
        this.displayOrderPreview = trueOrFalse
        window.scrollTo(0, 0)
      }
    },
  },
  computed: {
    ...mapGetters({
      gideonCheckoutPageInfo: 'gideonStore/gideonCheckoutPageInfo',
      gideonStoreCartCheckoutType: 'gideonStore/gideonStoreCartCheckoutType',
      gideonStoreShippingIndKey: 'gideonStore/gideonStoreShippingIndKey',
      userCamp: 'user/userCamp',
      prefCulture: 'user/userPreferredCulture',
      userLogin: 'user/userLogin',
      userCountryObject: 'user/userCountryObject',
    }),
    CartTotal() {
      let cartTotal = 0
      if (this.gideonCheckoutPageInfo.CartItems.length > 0) {
        const reducer = (accumulator, currentValue) => accumulator + currentValue
        cartTotal = this.gideonCheckoutPageInfo.CartItems.map((x) => x.sum_line_amount).reduce(reducer)
        if (this.expeditedProcessing == 'true') {
          cartTotal = cartTotal + this.gideonCheckoutPageInfo.ExpeditedShippingCost
        }
      }
      return cartTotal.toFixed(2)
    },
    CampFundBalanceAfterPurchase() {
      let cf = 0
      if (this.gideonCheckoutPageInfo.CartItems.length > 0 && this.gideonStoreCartCheckoutType === 'scripture') {
        cf =
          this.gideonCheckoutPageInfo.CampScriptureFundBalance -
          (this.CartTotal - this.gideonCheckoutPageInfo.ServiceTestamentTotal)
      }
      return cf.toFixed(2)
    },
    serviceCardItems() {
      let scItems = this.gideonCheckoutPageInfo.CartItems.map((x) => {
        return {
          shc_prd_key: x.shc_prd_key,
          vprd_product_name: x.vprd_product_name,
          vprd_prd_code: x.vprd_prd_code,
          shc_quantity: x.shc_quantity,
          sum_line_amount: x.sum_line_amount,
          label_quanity: this.gideonCheckoutPageInfo.Label_Quantity,
          label_item: this.gideonCheckoutPageInfo.Label_Item,
          label_price: this.gideonCheckoutPageInfo.Label_Price,
        }
      })
      return scItems
    },
    shippingMembers() {
      let sMem = []
      if (this.gideonCheckoutPageInfo.CampMembers.length > 0) {
        sMem = this.gideonCheckoutPageInfo.CampMembers.map((x) => {
          return {
            value: x.ind_key,
            text: x.name,
          }
        })
      }

      return sMem
    },
    shippingAddresses() {
      let filteredList = []
      let sMem = []
      if (this.gideonCheckoutPageInfo.CurrentMemberShippingAddresses.length > 0) {
        if (this.shippingFilter === 'residential') {
          //residential
          filteredList = this.gideonCheckoutPageInfo.CurrentMemberShippingAddresses.filter(
            (a) => a.axe_adt_key.toLowerCase() !== this.commercialShippingAddressType.toLowerCase()
          )
        } else {
          //commercial
          filteredList = this.gideonCheckoutPageInfo.CurrentMemberShippingAddresses.filter(
            (a) => a.axe_adt_key.toLowerCase() === this.commercialShippingAddressType.toLowerCase()
          )
        }
        sMem = filteredList.map((x) => {
          return {
            value: x.axe_key,
            text: x.adr_line1,
            display_address: x.address,
          }
        })
      }

      return sMem
    },
    selectedShipAddressDisplay() {
      let addrDisplay = {}
      if (
        this.gideonCheckoutPageInfo.CurrentMemberShippingAddresses.length > 0 &&
        this.selectedShippingAddress !== null
      ) {
        let thisAddr = this.gideonCheckoutPageInfo.CurrentMemberShippingAddresses.find((x) => {
          return x.axe_key === this.selectedShippingAddress
        })
        addrDisplay.addr_line1 = thisAddr.adr_line1
        addrDisplay.address = thisAddr.address
        this.AdditionalInfoAdr3 = thisAddr.adr_line3
      }
      return addrDisplay
    },
    shippingMemberDisplay() {
      let sMem = this.shippingMembers.find((x) => {
        return x.value === this.selectedShippingMember
      })
      return sMem.text
    },
    billingAddresses() {
      let sMem = []
      if (this.gideonCheckoutPageInfo.CurrentMembersBillingAddresses.length > 0) {
        sMem = this.gideonCheckoutPageInfo.CurrentMembersBillingAddresses.map((x) => {
          return {
            value: x.axe_key,
            text: x.adr_line1,
            display_address: x.address,
          }
        })
      }

      return sMem
    },
    selectedBillAddressDisplay() {
      let addrDisplay = {}
      if (
        this.gideonCheckoutPageInfo.CurrentMembersBillingAddresses.length > 0 &&
        this.selectedBillingAddress !== null
      ) {
        let thisAddr = this.gideonCheckoutPageInfo.CurrentMembersBillingAddresses.find((x) => {
          return x.axe_key === this.selectedBillingAddress
        })
        addrDisplay.addr_line1 = thisAddr.adr_line1
        addrDisplay.address = thisAddr.address
      }
      return addrDisplay
    },
    optionsExpireYear() {
      const currentYear = new Date().getFullYear()
      const startYear = currentYear - 3
      let years = Array.from(new Array(14), (val, index) => {
        return { value: currentYear + index, text: currentYear + index }
      })
      return years
    },
    optionsExpireMonth() {
      let months = date_data.months.map((x) => {
        return {
          value: x.value.length === 1 ? '0' + x.value : x.value,
          text: x.value.length === 1 ? '0' + x.value : x.value,
        }
      })
      return months
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('service-card', 'store-topnav-search'),
        this.getComponentTranslations(
          'common.address-types',
          'common.attire',
          'common.church-service-type',
          'common.days',
          'common.email-types',
          'common.fax-types',
          'common.months-abbreviations',
          'common.phone-types'
        ),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
        this.page_load()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.page-header {
  p {
    text-align: center;
    margin-top: 20px;
  }
}

.btn-top {
  padding: 8px;
}

.label-strong {
  font-weight: bold;
}

.col-cart-total {
  border-top: 1px solid #747474;
  padding-top: 16px;
}

.cartTotal {
  color: #000000;
}

.tooltip .tooltip-inner {
  max-width: 500px !important;
  width: 450px !important;
}

#body_Checkout_cvc_visa {
  width: 150px;
}

.top-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;

  .top-col {
    .top-nav-search {
      width: 100%;
    }
  }
}

.what-link {
  font-size: 75%;
}

.text-1 {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.text-2 {
  text-align: center;
  color: $secondary-color;
  width: 70%;
  margin: 0 auto;
}

.special-instructions {
  font-weight: bold;
  color: $primary-color;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;

  .selectMonth {
    width: 75px;
    padding: 8px !important;
  }

  .selectYear {
    width: 100px;
    padding: 8px !important;
  }

  .inputCVC {
    width: 60px;
    padding: 8px !important;
  }
}

.switch-buttons {
  margin: 0 auto;
  text-align: center;

  .merchscriptBtn {
    background-color: #747474;
  }

  .merchscriptBtnSelected {
    background-color: $secondary-color;
  }
}

.altButton {
  background-color: #747474 !important;
  padding: 8px;

  &:hover {
    background-color: $primary-color  !important;
  }
}

.tile-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;
  background-color: #ffffff;
  padding-top: 15px;
  margin-bottom: 25px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.2) !important;

  .tile-col-title {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;

    .category-ddl {
      display: flex;
      flex-direction: row;
      align-content: flex-end;
      justify-content: space-between;

      .btn-go {
        padding: 8px;
      }
    }
  }

  .tile-col-details {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;

    .details-div {
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      justify-content: flex-start;
    }

    .form-div {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: flex-start;
    }

    .tile-row {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: flex-start;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      border: 0;
    }
  }

  .tile-col {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;

    .tile-col-card {}
  }
}

.tiles {
  margin-bottom: 80px;
}
</style>
